.App {
  background: rgb(18,13,110);
  background: linear-gradient(59deg, rgba(18,13,110,1) 0%, rgba(9,9,121,1) 32%, rgba(5,167,200,1) 100%);
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.inp-fntend {
  

  width: 100%;

}

h1 {
  font-family: 'Roboto', sans-serif;
  color: aliceblue;
  font-size:25px;
}

.btn {
  background-color: orangered;
  border: none;
  color: white;
  padding: 16px 32px;
  text-decoration: none;
  margin: 4px 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:25px;
}

.row {
  
  display: flex;
  
  
}
.row1{
  flex: 100%;
  margin: 50px;
 
}

form{
  background: rgb(131,58,180);
  background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(228,41,41,1) 0%, rgba(218,145,42,1) 100%);
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
}

.centre{
  
  background: rgb(18,13,110);
  background: linear-gradient(59deg, rgba(18,13,110,1) 0%, rgba(9,9,121,1) 32%, rgba(5,167,200,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.font-link {
  font-family: 'Roboto', sans-serif;
}

/* .row1 label {
  display:block;
  text-align: center;
  font-size: 20px;
  margin: 0 0 2vh 0;
}*/

.row1 input, textarea{
  display: block;
  width: 100%;
  padding: .5rem .8rem .5rem .8rem;
  margin: .9vw 0;
  border: 0;
  border-radius: 5px;
  font-size: 20px;
}

.row textarea {

  height: 15vh;
}

.row label{
  color: aliceblue;
}



.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
		-webkit-tap-highlight-color: transparent;
}

button {
	width: 150px;
	height: 50px;
	cursor: pointer;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #7f5a83;
	background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
	background-size: 200% 100%;
	background-position: left;
	background-repeat: no-repeat;
	transition: 500ms;
}

button:before {
	color: rgba(255,255,255,0.5);
	font-size: 16px;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 40px;
	background-color: #1b2845;
	background-image: linear-gradient(315deg, #1b2845 0%, #274060 74%);
	transition: 500ms;
}

button:hover {
	background-position: right;
}

button:hover:before {
	color: rgba(255,255,255,0.8);
}

button:focus {
	outline: none;
}
.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}